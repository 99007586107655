import { logOut } from '@/state/user/actions'

import { PUBLIC_PATH } from '@/components-v2/organisms/RouterGuard/RouterGuard'

export const clearUserStates = (dispatch) => {
  dispatch(logOut())
  if (window.localStorage.getItem('walletconnect')) {
    window.localStorage.removeItem('walletconnect')
    // connectorsByName.walletconnect.close()
    // connectorsByName.walletconnect.deactivate()
    // connectorsByName.walletconnect.walletConnectProvider = null
  }
  if (!PUBLIC_PATH.includes(window.location.pathname)) {
    // window.localStorage.removeItem(connectorLocalStorageKey)
  }
}
