import { wait } from '@/utils/wait'
import { useEffect } from 'react'
import { useAccount, useConnect } from 'wagmi'
import useConnectWeb3 from './useConnectWeb3'

function getCurrentConnectorId(data: any): string | null {
  const { connections, current } = data

  if (!connections || connections.__type !== 'Map' || !Array.isArray(connections.value) || !current) {
    console.error('Invalid data structure')
    return null
  }

  // Iterate over the connections map to find the matching current connection
  for (const [key, value] of connections.value) {
    if (key === current) {
      return value.connector?.id || null // Return the connector ID if it exists
    }
  }

  // Return null if the current connection is not found
  console.warn('Current connection not found')
  return null
}

const useAutoConnectAccount = () => {
  const { connectors, isSuccess } = useConnect()
  const { isDisconnected, isConnected } = useAccount()

  const { connectMetamaskWallet, connectWalletConnect } = useConnectWeb3()

  useEffect(() => {
    const autoConnect = async () => {
      // On initial load, sometimes `wagmi.store` is null
      await wait(100)

      if (!isDisconnected) return

      const wagmiStoreRaw = localStorage.getItem('wagmi.store')
      const wagmiStore = JSON.parse(wagmiStoreRaw || '{}')

      const isWagmiConnected = wagmiStore?.state?.connections?.value?.length > 0

      if (!isWagmiConnected) {
        try {
          // To check when ussr locked metamask
          const accounts: any = await window.ethereum.request({
            method: 'eth_requestAccounts'
          })

          // if (accounts?.length) return
          return
        } catch (error) {
          console.error(error)
        }
      } else {
        const recentConnectorId = JSON.parse(localStorage.getItem('wagmi.recentConnectorId') || 'injected')

        const connectionId = getCurrentConnectorId(wagmiStore?.state) || recentConnectorId

        if (connectionId === 'walletConnect') {
          connectWalletConnect()
        } else {
          connectMetamaskWallet()
        }
      }
    }

    autoConnect()
  }, [connectors, connectWalletConnect, connectMetamaskWallet])
}

export default useAutoConnectAccount
