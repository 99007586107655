import React from 'react'
import { Provider } from 'react-redux'
import { Store } from '@reduxjs/toolkit'
import { MantineProvider } from '@mantine/core'
import { mantineTheme } from './shared/theme/mantine.theme'
import { Notifications } from '@mantine/notifications'
import CustomWagmiProvider from './contexts/CustomWagmiProvider'

const Providers: React.FC<{ children; store: Store }> = ({ children, store }) => (
  <Provider store={store}>
    <CustomWagmiProvider>
      <MantineProvider theme={mantineTheme}>
        <Notifications position="top-right" zIndex={10000} />
        {children}
      </MantineProvider>
    </CustomWagmiProvider>
  </Provider>
)

export default Providers
