import { captureException as sentryCaptureException } from '@sentry/nextjs'
import { useCallback, useState } from 'react'
import { useConnect } from 'wagmi'
import useStorage from '../utility/useStorage'

const useConnectWeb3 = () => {
  const { setItem } = useStorage('session')

  const [localError, setLocalError] = useState('')

  const { connectAsync, connectors, error } = useConnect()

  const connectMetamaskWallet = useCallback(async (skipLockedCheck = false) => {
    setLocalError('')
    if (typeof window.ethereum === 'undefined') {
      window.open('https://metamask.io/download/', '_blank')
      return
    }

    try {
      // To check when ussr locked metamask
      const accounts: any = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })

      if (!accounts?.length || skipLockedCheck) {
        const injectedConnector = connectors.find((connector) => connector.id === 'injected')
        await connectAsync({ connector: injectedConnector })
      }

      setItem('wallet-provider', 'injected')
    } catch (err) {
      sentryCaptureException(err ?? error)
      console.log('Metamask Connection Error: ', err)
    }
  }, [connectors, connectAsync])

  const connectWalletConnect = useCallback(async () => {
    setLocalError('')
    try {
      const walletConnectConnector = connectors.find((connector) => connector.id === 'walletConnect')
      await connectAsync({ connector: walletConnectConnector })

      setItem('wallet-provider', 'wallet-connect')
    } catch (err) {
      sentryCaptureException(err)
      console.log('WalletConnect Connection Error: ', err)
    }
  }, [connectors, connectAsync])

  return { error: localError, connectMetamaskWallet, connectWalletConnect }
}

export default useConnectWeb3
